import React from "react"
import { graphql } from "gatsby"
import Layout from "../../layouts/en"
import MyAccountPageTemplate from "../../templates/my-account-page"

const MyAccount = props => {
  const { title } = props.data.markdownRemark.frontmatter

  return (
    <Layout location={props.location} title={title}>
      <MyAccountPageTemplate {...props} />
    </Layout>
  )
}

export default MyAccount

export const pageQuery = graphql`
  query MyAccountPageEN {
    markdownRemark(
      fileAbsolutePath: { regex: "/static-pages/my-account/index.en.md/" }
    ) {
      frontmatter {
        ...LoginPage
      }
    }
  }
`
